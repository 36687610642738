import { CommonModule, DatePipe } from '@angular/common';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';

import { DetailDialogComponent } from './components/detail-dialog/detail-dialog.component';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsFilterModule } from '@design-system/components/filter';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPageModule } from '@design-system/feature/page';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsTableSettingsModule } from '@design-system/components/table-settings';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GetTypePipe } from './pipes/getType/getType.pipe';
import { GetURLPipe } from './pipes/getURL/getURL.pipe';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { InputComponent } from './components/input/input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GetAnalyticsTablePageSizePipe } from '../analytics/get-analytics-table-page-size.pipe';

const sharedModules = [
  //Angular
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  TranslateModule,
  GoogleAnalytics4DirectiveModule,
  //shared
  PdLetDirectiveModule,
  DsTableModule,
  DsPlaceholderModule,
  DsTextIndicatorModule,
  DsSpacingModule,
  DsTableSettingsModule,
  DsTableLoadingModule,
  DsPageModule,
  DsLoadingModule,
  DsEquipmentIconModule,
  DsSnackbarModule,
  DsFilterModule,
  DsFilterInputModule,
  DsLoadingModule,
  //Material
  MatIconModule,
  MatTableModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatOptionModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatDialogModule,
  MatDatepickerModule,
  MatSortModule,
  MatMenuModule,
  MatRadioModule,
];

const sharedPipes = [GetURLPipe, GetTypePipe, GetAnalyticsTablePageSizePipe];
const sharedComponents = [InputComponent, DetailDialogComponent];

@NgModule({
  declarations: [...sharedPipes, ...sharedComponents],
  imports: [...sharedModules],
  exports: [...sharedModules, ...sharedPipes, ...sharedComponents],
  providers: [DatePipe],
})
export class SharedTelematicDoctorModule {}
