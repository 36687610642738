import { Injectable, inject } from '@angular/core';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { SnackbarActions } from './snackbar.actions';

@Injectable()
export class SnackbarEffects {
  private _actions: Actions = inject(Actions);

  showError$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(SnackbarActions.ShowError),
        switchMap((action) =>
          this._snackbar.queue(action.message, {
            type: DsSnackbarType.Error,
          }),
        ),
      ),
    { dispatch: false },
  );

  showSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(SnackbarActions.ShowSuccess),
        switchMap((action) =>
          this._snackbar.queue(action.message, {
            type: DsSnackbarType.Success,
          }),
        ),
      ),
    { dispatch: false },
  );

  constructor(private readonly _snackbar: DsSnackbar) {}
}
